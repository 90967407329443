<template>
	<div class="list-tool-bar">
    <el-button @click="emit('onCreate')" :disabled="disabled" type="primary">{{ t('table.create') }}</el-button>
    <el-button @click="emit('onDelete')" type="primary" plain>{{ t('form.delete') }}</el-button>
		<div class="search">
			<div class="search_item">
				<el-select
          v-model="searchData.filter_user_id"
          :placeholder="t('dashboard.sales')"
          filterable
          clearable
          @change="getProduct">
					<el-option
            v-for="(item, index) in salesList"
            :key="index"
            :label="item.label"
            :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search_item">
				<el-select
          v-model="searchData.filter_product_id"
          :disabled="!!!searchData.filter_user_id"
          :placeholder="t('order.select_product_create')"
          filterable
          clearable
					@change="doSearch">
					<el-option
            v-for="(item, index) in productList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue'
import { getUserList, getUserProduct } from '@/utils/common'

const props = defineProps({
	disabled: {
		type: Boolean,
		default: false
	}
})
const t = inject('t')
const emit = defineEmits(['getList', 'onCreate', 'onDelete'])
const searchData = ref({
	page: 1
})
const productList = ref([])
const salesList = ref([])
// 用户产品列表
const getProduct = async (data) => {
	delete searchData.value.filter_product_id
	if (data) {
		productList.value = await getUserProduct({ filter_user_id: data })
	} else {
		delete searchData.value.filter_user_id
	}
  searchData.value.page = 1
	doSearch()
}

onMounted(async () => {
	// 获取销售列表
	salesList.value = await getUserList({ filter_role_alias: 'SalesMan' })
})

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (searchData.value[key] === '') {
			delete searchData.value[key]
		}
	}
	emit('getList', searchData.value)
}
</script>
